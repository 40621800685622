.main-header {
  font-weight: 300;
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  /*padding: 0;*/
  z-index: 5;
}

main {
  margin-top: 9rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
