.event-item {
  margin: 1rem 0;
}

.event-item__content {
  padding: 0;
}

.event-item__info {
  padding: 1rem;
  text-align: center;
}

.event-item__info h3,
.event-item__info h4,
.event-item__info p {
  margin: 0 0 0.5rem 0;
}

.event-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.event-item__modal-content {
  padding: 0;
}

.event-item__modal-actions {
  text-align: right;
}

.event-item__actions button,
.event-item__actions a {
  margin: 0.5rem;
}

@media (min-width: 768px) {
  .event-item__image {
    height: 20rem;
  }
}
