@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #fafafa;

  font-size: 16px;
  color: #000;
}

.title {
  font-size: 3em;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;
}

h3 {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.vertical-timeline-element-title {
  font-weight: bold !important;
}

.date {
  color: rgb(0, 0, 0);
}

#description {
  margin: 1.5em 0 2em 0;
  padding-right: 5px;
  font-weight: lighter !important;
  overflow: auto;
  display: block;
}

.button {
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  color: white;
}

.schoolButton {
  background-color: #4f2683;
}

.schoolButton:hover {
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #4f2683;
  border: 2px solid #4f2683;
}

.cultureButton {
  background-color: #e98c49;
}

.cultureButton:hover {
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #e97f34;
  border: 2px solid #e97f34;
}

.statsButton {
  background-color: #54e690;
}

.statsButton:hover {
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #32c971;
  border: 2px solid #32c971;
}

@media only screen and (max-width: 1700px) {
  .vertical-timeline-element-date {
    display: block !important;
    float: none !important;
    color: black;
    margin-top: 1.5em;
  }
}

.read-more {
  color: rgb(52, 52, 250);
}

.read-more:hover {
  text-decoration: underline;
}

.show-less:hover {
  text-decoration: underline;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.event-button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #4f2683;
  border-radius: 4px;
  background: #4f2683;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.event-button:focus {
  outline: none;
}

.event-button:hover,
.event-button:active {
  background: #391c5e;
  color: white;
  border-color: #391c5e;
}

.event-button--inverse {
  background: transparent;
  color: #4f2683;
}

.event-button--create {
  background: #391c5e;
  color: white;
  border-color: #391c5e;
}

.event-button--create:hover,
.event-button--create:active {
  background: #5d2f99;
  color: white;
  border-color: #5d2f99;
}

.event-button--inverse:hover,
.event-button--inverse:active {
  background: #4f2683;
  color: white;
}

.event-button--danger {
  background: #c0392b;
  border-color: #c0392b;
}

.event-button--danger:hover,
.event-button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.event-button:disabled,
.event-button:hover:disabled,
.event-button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.event-button--small {
  font-size: 0.8rem;
}

.event-button--big {
  font-size: 1.5rem;
}

.badge-container {
  text-align: center;
}

.badge {
  height: 70px;
  width: 70px;
  border: 5px solid white;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  margin: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
}

.badge-name {
  text-align: center;
  margin: 0 !important;
}

.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.event-item {
  margin: 1rem 0;
}

.event-item__content {
  padding: 0;
}

.event-item__info {
  padding: 1rem;
  text-align: center;
}

.event-item__info h3,
.event-item__info h4,
.event-item__info p {
  margin: 0 0 0.5rem 0;
}

.event-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.event-item__modal-content {
  padding: 0;
}

.event-item__modal-actions {
  text-align: right;
}

.event-item__actions button,
.event-item__actions a {
  margin: 0.5rem;
}

@media (min-width: 768px) {
  .event-item__image {
    height: 20rem;
  }
}

.event-list {
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  width: 90%;
  max-width: 40rem;
}

.events-list {
  margin-top: 10rem;
}

.event-form {
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}

.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}

.image-upload.center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.image-upload__preview {
  width: 13rem;
  height: 13rem;
  border: 1px solid #ccc;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}
table,
td {
  border: 1px solid #ddd;
  border-collapse: collapse;
}

th,
td {
  padding: 15px;
  text-align: center;
}

.users-card {
  margin: 1rem auto;
  width: 90%;
  max-width: 40rem;
}

.users-list {
  width: 100%;
}

.users-list th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #4f2683;
  border-color: #4f2683;
  color: white;
}

.users-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

.users-list tr:hover {
  background-color: #ddd;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: #fafafa;
}

.main-navigation__title {
  color: #4f2683;
  margin: 0;
}

.main-navigation__title a {
  text-decoration: none;
  color: #4f2683;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.main-header {
  font-weight: 300;
  width: 100%;
  height: 7rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  /*padding: 0;*/
  z-index: 5;
}

main {
  margin-top: 9rem;
}

@media (min-width: 768px) {
  .main-header {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.nav-links li {
  margin: 1rem;
  font-weight: bold;
}

.nav-links a {
  border: 1px solid transparent;
  color: #4f2683;
  text-decoration: none;
  padding: 0.7rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  transition: all 0.2s ease-in-out;
  border: 2px solid #4f2683;
  border-radius: 5px;
  color: #4f2683;
}

.nav-links button {
  cursor: pointer;
  border: 3px solid #4f2683;
  color: #4f2683;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  transition: all 0.2s ease-in-out;
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  transition: all 0.2s ease-in-out;
  color: #fff;
}

@media (min-width: 768px) {
  .nav-links {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: #4f2683;
    text-decoration: none;
  }

  .nav-links button {
    border: 2px solid #4f2683;
    color: #4f2683;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    transition: all 0.2s ease-in-out;
    color: #fff;
  }
}

